import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
// Vue.component('vue-draggable-resizable', VueDraggableResizable)

import VueDragResizeRotate from "@gausszhou/vue-drag-resize-rotate"
Vue.component('vue-drag-resize-rotate', VueDragResizeRotate)

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

Vue.use(ElementUI);


// 实现自定义加载动画效果
/// 通过下面的方法生成加载实例or消除
/// this.$showLoading.show(document.body);  括号里是DOM元素
/// this.$showLoading.hide();
import loading from './assets/loading/index';
Vue.use(loading);


Vue.config.productionTip = false
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
