/**
 * @author Cloud-Iris
 * 实现自定义加载实例的插件JS
 */

import Vue from 'vue';

import CustomLoadingBlock from '../../components/loading/CustomLoadingBlock.vue';

const LoadingConstructor = Vue.extend(CustomLoadingBlock);

/// 创建加载实例常量
const instance = new LoadingConstructor({
  el: document.createElement('div')
});

instance.show = false;

/// 创建  加载实例 + 方法 的加载常量
/// 通过下面的方法生成加载实例or消除
/// this.$showLoading.show(document.body);
/// this.$showLoading.hide();
const loading = {
  // 要呈现加载区域的目标元素
  show(targetParentElement) {
    instance.show = true
    // document.body.appendChild(instance.$el)
    targetParentElement.appendChild(instance.$el)
  },
  hide() {
    instance.show = false
  },
};

export default {
  install() {
    if (!Vue.$showLoading) {
      Vue.$showLoading = loading
    }
    Vue.mixin({
      created() {
        this.$showLoading = Vue.$showLoading
      }
    })
  }
}
