import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/client/IndexView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/account/LoginView.vue')
  },
  //项目内部路由 有关项目的路由请写在下方children中 比如原型设计 uml图的绘制 在线文档编辑
  {
    path: '/projectdetail',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/client/ProjectIndex.vue'),
    children: [
      {
        path: 'prototype',
        name: 'PrototypeDesign',
        meta: {
          title: '原型设计'
        },
        component: () => import('../views/client/project/PrototypeDesign.vue')
      },
      {
        path: 'umllist',
        name: 'UmlList',
        meta: {
          title: 'UML_LIST'
        },
        component: () => import('../views/client/project/UmlList.vue')
      },
      {
        path: 'uml',
        name: 'UmlDesign',
        meta: {
          title: 'UML'
        },
        component: () => import('../views/client/project/UmlIframe.vue')
      },
      // {
      //   path: 'collaborative-manage', //   /projectdetail/collaborative-manage
      //   name: 'CollaborativeManage',
      //   meta: {
      //     title: '多人即时协作文本管理页面'
      //   },
      //   component: () => import('../views/client/project/CollaborativeManagePage.vue')
      // },
      // {
      //   path: 'collaborative-editor', //   /projectdetail/collaborative-editor
      //   name: 'CollaborativeEditor',
      //   meta: {
      //     title: '多人即时协作文本编辑器'
      //   },
      //   component: () => import('../views/client/project/CollaborativeEditorPage.vue')
      // },
      {
        path: 'project-document',  //   /projectdetail/project-document
        name: 'ProjectDocument',
        meta: {
          title: '该项目下的文档管理中心'
        },
        component: () => import('../views/client/project/ProjectDocument.vue')
      }

    ]
  },
  //首页路由
  {
    path: '/project',
    name: 'ProjectList',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/client/team/TeamProject.vue')
  },
  {
    path: '/team',
    name: 'NumberList',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/client/team/TeamNumber.vue')
  },
  {
    path: '/document',
    name: 'DocumentCenter',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/client/team/TeamDocument.vue')
  },
  {
    path: '/prototype/preview/:project_id',
    name: 'PrototypePreview',
    meta: {
      requireAuth: false
    },
    component: () => import('../views/client/project/PrototypePreview.vue')
  },
  // //drawio路由
  // {
  //   path: '/drawio/?diagram_token=:id',
  //   name: 'DrawIo',
  //   component: ()
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // 通过 Vuex 获取用户登录信息（在实战篇中会介绍到）
  const userInfo = localStorage.getItem('user')

  localStorage.setItem('preRoute',from.name)
  // 若用户未登录且访问的页面需要登录，则跳转至登录页面
  if (userInfo == null && to.meta.requireAuth) {
    Message.error('请先登录')
    next({
      name: 'Login',
    })
  }

  next()
})

export default router
